.contact-info-block{
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    /*justify-content: space-evenly;*/
}
.light .contact-info-block{
    background-color: #e28b6b;
}

.dark .contact-info-block{
    background-color: black;
}

.contact-info {
    gap: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-evenly;
    height: 100%;
    max-width: 700px;
}

.dark .contact-info{
    background-color: black;
    color: white;

}

.light .contact-info{
    background-color: #e28b6b;
    /*background-color: #F2EEE9;*/
    color: black;

}


.contact-info-title{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 22px;
}

.light .contact-info-title{
    color: white;
    font-weight: 400;
}

.contact-info input[type="checkbox"] {
    margin-right: 10px;
}

.contact-info a,.contact-info .policy-label  {
    color: white;
}

.name-and-phone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input {
        box-sizing: border-box;
        width: auto;
        margin: 10px;
        padding: 10px;
        text-align: center;
    }

    .phone-input{
        margin-left: 11px;
    }
}

.dark .name-and-phone input{
    border-radius: 10px;

}

.light .name-and-phone input{
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    color: white;
}

.light .name-and-phone input::-webkit-input-placeholder {
    color: white !important;
}

.policy-label{
    font-size: 14px;
}

.policy-label a{
    text-decoration: none;
}

.submit-btn {
    box-sizing: border-box;
    font-size: 20px;
    color: white;
    padding: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    margin: 10px 0;
    background-color: #1c8f6a;
}

.dark .submit-btn{
    border: 1px rgb(1, 138, 110) solid;
    border-radius: 10px;

}
.light .submit-btn{
    text-transform: uppercase;
    border: none;
    font-weight: 300;
    /*border-radius: 0;*/
    /*background-color: rgb(255, 136, 108);*/

}

.submit-btn:hover {
    transform: scale(1.05);
}

.submit-btn:focus {
    color: #1c8f6a;
    background-color: rgb(204, 204, 204);
    border: 1px rgb(1, 138, 110) solid;

}

@media (max-width: 680px) {
    .name-and-phone {
        flex-direction: column;
    }
}

.progress-bar-container {
    width: 80%;
    height: 10px;
    background-color: #ddd;
    /*background-color: rgb(1, 138, 110);*/
    /*background-color: rgb(255, 136, 108);*/
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    margin: 20px auto;
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    width: 0;
    border-radius: 8px;
    transition: width 0.4s ease-in-out;
}

.dark .progress-bar-fill {
    background-color: rgb(1, 138, 110);
}

.light .progress-bar-fill {
    background-color: rgb(1, 138, 110);
    /*background-color: rgb(1, 138, 110);*/
    /*background-color: rgb(255, 136, 108);*/
}


@media (max-width: 680px) {
    .progress-bar-container {
        width: 90%;
    }
}

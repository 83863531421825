.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    max-width: 80%;
    color: black;
}

.modal-body button {
    background-color: #444;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-body button:hover {
    background-color: #666;
}
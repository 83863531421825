.arrow-button {
    box-sizing: border-box;
    /*padding: 10px 20px*/
    cursor: pointer;
    transition: all 0.3s ease;
    color: gray;

    /*background-color: rgb(1, 138, 110);*/
    background-color: black;
    color: white;
    padding: 10px;
    /*border: none;*/
    /*border-color: rgb(1, 138, 110);*/
    display: block;
    /*border: 1px #ccc solid;*/
    /*border: 1px white solid;*/
    position: relative;
    width: 100%;
    margin: 10px;
}

.dark .arrow-button {
    border: 1px black solid;
    border-radius: 5px;

}

.light .arrow-button {
    border: none;
    border-radius: 0;

}

/* Стили для варианта 'primary' */
.dark .arrow-button.primary {
    background-color: rgb(1, 138, 110);
}

.light .arrow-button.primary {
    background-color: rgb(1, 138, 110);
}

/* Стили для варианта 'secondary' */
.dark .arrow-button.secondary {
    background-color: black;
    color: white;
}

.light .arrow-button.secondary {
    background-color: #e28b6b;
    /*color: white;*/
}

.arrow-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
@media (max-width: 680px) {
    .background-image {
        border-radius: 0;
        width: 100%;
        height: 100vh;
    }

    .visit-website-link {
        margin-left: 0;
        margin-top: 25px;
        position: absolute;
        bottom: 45px;
    }
}

@media (min-width: 680px) {
    .background-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 552px;
        height: 90%;
        min-width: 700px;
        max-width: 900px;
        width: 96%;
        background: linear-gradient(180deg, rgba(10, 10, 10, 0.7), transparent), url('desire.png');
        background-position: center center;
        background-size: cover;
    }
}

@media (max-width: 430px) {
    .visit-website-btn{
        padding: 15px;
    }
}

.visit-website-btn{
    padding: 16px 20px;
    font-weight: 400;
    font-size: 1em;
    margin-top: 50px;
    width: min-content;
    min-width: 250px;
    max-width: 100%;
}

.title {
    max-width: 400px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 14px;
    margin-top: 20px;
    white-space: pre-wrap;
}


.subtitle {
    color: #fff;

    font-size: 18px;
    font-weight: 400;
    white-space: pre-wrap;
    margin-bottom: 14px;
}
* {
  box-sizing: border-box;
  /*font-family: Mulish, sans-serif;*/

  /*font-family: "Tenor Sans", serif;*/
  transition: all .2s ease;
  outline: none;
}

.dark{
  font-family: "Tenor Sans", serif;
}

.light{
  font-family: "Proxima Nova";

}
.light button{
  font-family: "Proxima Nova";
}

body{
  background-color: #F2EEE9;
}

body.embed {
  background-color: rgba(242, 238, 233, 0);
}


main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.orange-btn{
  text-transform: uppercase;
  display: block;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  background-color: #E1886C;
  border: 1px solid #E1886C;
  color: rgb(245, 240, 240);
  fill: rgb(245, 240, 240);
}

.orange-btn:hover{
  background-color:  #3A8A63;
  border: 1px solid #3A8A63;
}



.answers {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.question-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 50px;
    justify-items: center;
    justify-content: space-evenly;
}

.dark .question-body {

    color: white;
}

.light .question-body {
    text-transform: uppercase;

    color: rgb(98,93,91);
}

.question-img {
    /*height: 90px;*/
    width: 100%;
    margin-bottom: 5px;
}

.question {
    font-size: 20px;
    align-content: center;
    justify-content: center;
    align-self: center;
    text-align: center;
    /*margin-bottom: auto*/
    margin-bottom: 10px;
}

@media (max-width: 680px) {
    .answers {
        flex-direction: column;
    }
}
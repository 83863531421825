.questions-page {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
}

.dark .questions-page{
    background-color: black;
}

.light .questions-page{
    background-color: rgb(242,238,233);
}




/*.questions-page.embed {*/
/*    background-color: rgba(0, 0, 0, 0.8);*/
/*}*/

.arrows {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 10px;
    box-sizing: border-box;
}

@media (max-width: 680px) {
    .questions-page {
        min-height: 100vh;
        height: 100%;
        border-radius: 0;
    }
}

@media (min-width: 680px) {
    .questions-page {
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 500px;
        min-width: 700px;
        max-width: 900px;
        width: 96%;
        transform: translate(-50%, -50%);
    }
    .light .button-div{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.background-image {
    background:
            linear-gradient(180deg,rgba(10, 10, 10, 1), transparent),
            url('desire.png');
    background-size: cover;
    background-position: center;
    margin: auto;

    display: flex;
    flex-direction: column;
    min-height: 500px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 10px 5px #0003;
    border-radius: 10px;

    a{
        text-decoration: none;
    }
}

.embed .background-image{
    display: flex;
    flex-direction: column;
    min-height: 500px;
    border-radius: 0;
    width: 100%;
    height: 100vh;
}


/* mobile */
@media (max-width: 680px) {
    .background-image {
        border-radius: 0;
        width: 100%;
        height: 100vh;
        a{
            margin-left: 0;
            margin-top: 25px;
            position: absolute;
            bottom: 45px;
        }
    }
}

/* PC */
@media (min-width: 680px) {
    .background-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 552px;
        height: 90%;
        min-width: 700px;
        max-width: 900px;
        width: 96%;
        background: linear-gradient(180deg, rgba(10, 10, 10, 0.7), transparent), url('desire.png');
        background-position: center center;
        background-size: cover;
    }

    .background-image.embed {
        display: flex;
        flex-direction: column;
        min-height: 500px;
        border-radius: 0;
        width: 100%;
        height: 100vh;
    }





    .start-quiz-btn{
    }
}

@media (max-width: 430px) {
    .start-quiz-btn{
        padding: 15px;
    }
}

.title {
    max-width: 400px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    text-align: center;
    margin-bottom: 14px;
    margin-top: 20px;
    white-space: pre-wrap;
}


.subtitle {
    color: #fff;

    font-size: 20px;
    font-weight: 400;
    white-space: pre-wrap;
    margin-bottom: 14px;
    max-width: 60%;
}

.start-quiz-btn{

    padding: 16px 20px;
    font-weight: 400;
    font-size: 1em;
    margin-top: 50px;

    width: min-content;
    min-width: 250px;
    max-width: 100%;
}

.locale-switcher{
    position: absolute;
    top: 20px;
}

.turkey-flag{
    vertical-align: center;
    width: 30px;
    height: auto;
    margin-bottom: -1px;
    padding-bottom: 0;
}
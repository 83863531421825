.button {
    box-sizing: border-box;
    /*background-color: black;*/
    color: white;
    padding: 18px;
    cursor: pointer;
    transition: all 0.5s ease;
    position: relative;
    width: 100%;
    min-width: 200px;
    margin: 10px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.dark .button{
    border: 1px white solid;
    border-radius: 5px;
    background-color: black;
}



.light .button{
    border: none;
    /*border-radius: 0px;*/
    /*background-color: #b37f69;*/
    /*background-color: #be8369;*/
    background-color: #e28b6b;
    /*background-color: rgb(255, 136, 108);*/
    text-transform: uppercase;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

.dark .button:hover{
    box-shadow: 1px 3px 5px #1c8f6a;
}

.light .button:hover{
    /*background-color: #1c8f6a;*/
    /*border: 1px rgb(1, 138, 110) solid;*/
    box-shadow: 1px 3px 5px #1c8f6a;
    border: none;
}

.dark .button:focus{
    color: #1c8f6a;
    background-color: rgb(204, 204, 204);
    border: 1px rgb(1, 138, 110) solid;

}

.light .button:focus{
    color: white;
    background-color: #1c8f6a;
    /*border: 1px rgb(1, 138, 110) solid;*/

}

.dark .button.selected {
    color: #1c8f6a;
    background-color: rgb(204, 204, 204);
    border: 1px rgb(1, 138, 110) solid;
}

.light .button.selected {
    /*color: white;*/
    background-color: #1c8f6a;
    /*border: 1px rgb(1, 138, 110) solid;*/
}